:root {
  --primary-color: #2e3a59;
  --secondary-color: #edf1f7;
  --inner-gap: 1.5rem;

  font-family: system-ui, sans-serif;
  font-variant-numeric: tabular-nums;
  height: 100%;
  word-break: break-word;
}

body {
  padding: 24px;
  min-height: 100%;
  display: flex;
  margin: 0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

main {
  display: block;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0 var(--inner-gap);
  border-radius: var(--inner-gap);

  width: 300px;
}

app-login {
  display: block;
}

%block {
  margin-top: var(--inner-gap);
  margin-bottom: var(--inner-gap);
}

.logo-link {
  @extend %block;

  display: block;
}

.user {
  display: grid;

  grid-template-areas:
    'avatar name'
    'avatar email';
  grid-template-columns: auto 1fr;

  column-gap: var(--inner-gap);
}

.avatar {
  width: 48px;
  height: 48px;
  grid-area: avatar;
  border-radius: 4px;
  object-fit: cover;
  background-color: black;
}

.full-name {
  font-weight: bold;
  grid-area: name;
}

.email {
  grid-area: email;
}

h1 {
  @extend %block;

  font-size: 1.3rem;
}

h2 {
  @extend %block;

  font-size: 1.15rem;
}

a {
  color: currentColor;
}

ul {
  @extend %block;

  padding-left: var(--inner-gap);
}

pre {
  @extend %block;

  white-space: pre-wrap;
}

google-sign-in-button {
  @extend %block;
}

.button {
  @extend %block;

  border-radius: 4px;
  background-color: #fff;
  color: var(--primary-color);
  border: 1px solid #dadce0;
  padding: 0 10px;
  height: 38px;
  box-sizing: border-box;
  font: inherit;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
